import React from "react"
import Service from "../../components/Shared/Service"
import checkMark from "../../assets/icons/noun-check-mark.svg"
import databaseManagement1 from "../../assets/icons/noun_database management_1933862.svg"
import fullanalytic from "../../assets/icons/fullanalytic.svg"
import businessIntelligence from "../../assets/icons/businessIntelligence.svg"
import { graphql } from "gatsby"

const seoTitle = "Data Science  "
const mainHeading = "Data Science"
const seoDescription = `Atyeti is able to extract knowledge and insights from noisy, structured and unstructured data, and apply actionable insights. We help in collecting, organizing, protecting, and of storing data.`
const mainPara = `Elevate and modernize your data capabilities. Our comprehensive data services are designed to unlock even decades of legacy systems and enhance your data’s value and utility.`
const keyword = ` Data science, Statistical Analysis, Forward compatible, Artificial intelligenceI/ machine learning, Database Management, Business Insights, Data Validation, Data Quality, Transparency`
const serviceIcons = [
  { id: 1, name: "Analytics and Modeling" },
  { id: 2, name: "Data Strategy and Roadmap"},
  { id: 3, name: "Validation and QA"  },
  { id: 4, name: "Integration and Architecture" },
  { id: 5, name: "Security and Privacy"},
  { id: 6, name: "Migration and Transformation"},
  { id: 7, name: "Governance and Compliance"},
  { id: 8, name: "Visualization and Reporting"
 },
]

const section2Heading = `Our Services`
const section2Para = ` Atyeti is able to extract knowledge and insights from noisy,
structured and unstructured data, and apply actionable insights. We
help in collecting, organizing, protecting, and of storing data.`

const cardItems = [
  // {
  //   id: 1,
  //   icon: fullanalytic,
  //   heading: `Full Spectrum Analytics`,
  //   listItems: (
  //     <>
  //       <li>
  //         Analytics spans from simple validations on small bits of data to large
  //         scale, complex deep learning architectures handling multiple data
  //         types Database management, Data modeling and Visualization.
  //       </li>
  //       <li>
  //         Analyzing raw data to make conclusions about information. Automating
  //         the the techniques and processes of data analytics.
  //       </li>
  //       <li>Database management, Data modeling and Visualization.</li>
  //     </>
  //   ),
  // },
  {
    id: 1,
    icon: databaseManagement1,
    heading: `Data Management`,
    listItems: (
      <>
        <li>
        Achieve full transparency and governance across extensive, intricate data ingestion systems.
        </li>
        <li>
        Gain control over the entirety of your data ecosystem, from pipeline creation to seamless discovery.        </li>
        <li>
        Strategically and securely transition from years or even decades of legacy systems, paving the way for a modernized data strategy.
        </li>
        <li>
        Build for the future with scalable modular systems on a framework that allows you to easily adapt to the changing world.
        </li>
      </>
    ),
  },
  {
    id: 2,
    icon: businessIntelligence,
    heading: `Business Intelligence`,
    listItems: (
      <>
        <li>Start trusting your data teams’ reports with robust QA and demonstrable accuracy.</li>
        <li>
        Increase the complexity and sophistication of insights using modern tools and techniques instead of just wrestling with data.
        </li>
        <li>Gain the freedom to pivot and adapt with faster development times and low-latency systems.
        </li>
      </>
    ),
  },
  {
    id: 3,
    icon: fullanalytic,
    heading: `AI Powered Data`,
    listItems: (
      <>
        <li>
        AI/ML is no longer just a goal of data, it is a tool FOR data.
        </li>
        <li>
        Harness state of the art AI and ML capabilities to streamline your data pipelines, automate QA, ETL, and reconciliation.
        </li>
        <li>
        Automate data mining and modeling, unlock new methods of storage and retrieval, and fully realize the value of your data.
        </li>
      </>
    ),
  },
]

const ServiceDetails = ({ data }) => (
  <Service
    seoTitle={seoTitle}
    mainHeading={mainHeading}
    mainPara={mainPara}
    serviceIcons={serviceIcons}
    // section2Heading={section2Heading}
    cardItems={cardItems}
    section2Para={section2Para}
    caseItems={data}
    seoDescription={seoDescription}
    keyword={keyword}
    dataScience
    className="data-science"
  />
)
export const query = graphql`
  query DataScienceCaseQuery {
    casestudyJson(mainheading: { eq: "data-science" }) {
      data {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        para
        slug
        subheading
      }
      bannerimage {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
export default ServiceDetails
